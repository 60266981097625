import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = (): React.ReactElement => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "privacyPolicyPage", "privacyPolicyPageStatic"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
