import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import StaticPage from '../StaticPage/StaticPage';
import { FINWINPL_EMAIL_ROUTE, PRIVACY_EMAIL_ROUTE } from '../../const/routes.const';

const PrivacyPolicy = (): React.ReactElement => {
  return (
    <StaticPage
      title={<Trans>PRIVACY POLICY</Trans>}
      date={<Trans>Last updated January 4, 2023</Trans>}
    >
      <p>
        <Trans i18nKey="This Privacy Policy describes the collection and processing of information about you that may directly or indirectly identify you (“Personal Data”) by BLACKBIRD SECURE BROWSING LTD and its affiliates, authorized resellers and subsidiaries (“OCTO”, “Octo Browser”, “we”, “our” or “us”).">
          This Privacy Policy describes the collection and processing of information about you that
          may directly or indirectly identify you (“<strong>Personal Data</strong>”) by BLACKBIRD
          SECURE BROWSING LTD and its affiliates, authorized resellers and subsidiaries (
          <strong>“OCTO”, “Octo Browser”, “we”, “our” or “us”</strong>).
        </Trans>
      </p>
      <p>
        <Trans i18nKey="This Privacy Policy is designed to help you feel more confident about the privacy and security of your information when using or accessing any OCTO’s software, products and services (“Software”).">
          This Privacy Policy is designed to help you feel more confident about the privacy and
          security of your information when using or accessing any OCTO’s software, products and
          services (“<strong>Software</strong>”).
        </Trans>
      </p>
      <p>
        <Trans>
          In the Privacy Policy, we explain how we collect, use and transfer information. We also let you know your rights.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Attention: By using our Software and by sharing your Personal Data with us, you acknowledge that your Personal Data will be processed in the manner described in this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do not provide us with any information and do not use the Software.">
          <strong>Attention:</strong> By using our Software and by sharing your Personal Data with
          us, you acknowledge that your Personal Data will be processed in the manner described in
          this Privacy Policy. If you do not agree to the terms of this Privacy Policy, please do
          not provide us with any information and do not use the Software.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Note: This Privacy Policy does not constitute your ‘consent’ to the processing of your Personal Data. You may consent to the processing of Personal Data for one or more specific purposes, when we request such consent.">
          <strong>Note:</strong> This Privacy Policy does not constitute your ‘consent’ to the
          processing of your Personal Data. You may consent to the processing of Personal Data for
          one or more specific purposes, when we request such consent.
        </Trans>
      </p>

      <h5>
        <Trans>HOW DO WE COLLECT YOUR PERSONAL DATA?</Trans>
      </h5>
      <p>
        <Trans>
          We collect Personal Data in the following ways: if you provide it to us or automatically
          by electronic means, including with the help of cookies, when you use our Software.
        </Trans>
      </p>
      <h5>
        <Trans>WHAT PERSONAL DATA WE USE AND HOW?</Trans>
      </h5>
      <p>
        <Trans>
          We respect your privacy and aim to limit the Personal Data that we collect from you to the
          amount which is strictly necessary to fulfil the purposes of processing. Please see below
          what Personal Data we use and how.
        </Trans>
      </p>

      <div className="ant-table">
        <div className="ant-table-container">
          <div className="ant-table-content">

          </div>
        </div>
      </div>
      <table>
        <thead className="ant-table-thead">
          <tr>
            <th className="ant-table-cell">
              <Trans>Purpose of processing</Trans>
            </th>
            <th className="ant-table-cell">
              <Trans>Personal Data</Trans>
            </th>
            <th className="ant-table-cell">
              <Trans>Legal basis for the processing</Trans>
            </th>
            <th className="ant-table-cell">
              <Trans>Duration of storage</Trans>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          <tr>
            <td className="ant-table-cell">
              <Trans>Account creation and user authorization</Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans>Email; and (or)</Trans>
                </li>
                <li>
                  <Trans>Your login in the Telegram messenger</Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <p>
                <Trans>EULA and Terms of Use</Trans>
              </p>
              <p>
                <Trans>* Without providing this data you will not be able to use the Software</Trans>
              </p>
            </td>
            <td className="ant-table-cell">
              <Trans>
                3 months after the account is deleted; or immediately after deleting the account, if
                you ask for
              </Trans>
            </td>
          </tr>
          <tr>
            <td className="ant-table-cell">
              <Trans>Insurance of proper use of the Software, including troubleshoot</Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans i18nKey="Technical data such as IP address, device ID, general location data, device model, OS Version, date and time of access, referrer, URL parameters (the “Technical Data”)">
                    Technical data such as IP address, device ID, general location data, device
                    model, OS Version, date and time of access, referrer, URL parameters (the “
                    <strong>Technical Data</strong>”)
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey="Usage experience, i.e. information about your use of the Software (the “User Data”)">
                    Usage experience, i.e. information about your use of the Software (the “<strong>User Data</strong>”)
                  </Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <p>
                <Trans>EULA and Terms of Use</Trans>
              </p>
              <p>
                <Trans>* Without providing this data you will not be able to use the Software</Trans>
              </p>
            </td>
            <td className="ant-table-cell">
              <Trans>
                3 months after the account is deleted; or immediately after deleting the account, if
                you ask for
              </Trans>
            </td>
          </tr>
          <tr>
            <td className="ant-table-cell">
              <Trans>Users support</Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans>Technical Data</Trans>
                </li>
                <li>
                  <Trans>User Data</Trans>
                </li>
                <li>
                  <Trans>Other Personal Data provided by you in correspondence</Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <Trans>
                Our legitimate interest in receiving communication from you and reacting to it and
                your interest in getting our response
              </Trans>
            </td>
            <td><Trans>2 months after the issue you addressed is resolved</Trans></td>
          </tr>
          <tr>
            <td className="ant-table-cell">
              <Trans>
                Manage and optimize users’ experience by improving our knowledge of our users
              </Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans>Technical Data</Trans>
                </li>
                <li>
                  <Trans>User Data</Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <Trans>
                Our legitimate interest in evaluating and creating statistics on the use of the
                Software and your interest in the Software updates which will be tailored to your
                needs
              </Trans>
            </td>
            <td className="ant-table-cell">
              <Trans>
                3 months after the account is deleted; or immediately after deleting the account, if
                you ask for
              </Trans>
            </td>
          </tr>
          <tr>
            <td className="ant-table-cell">
              <Trans>
                Provide you with advertising, including that is relevant to your interests
              </Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans>Technical Data</Trans>
                </li>
                <li>
                  <Trans>User Data</Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <Trans>
                Our legitimate interest based on the balance of interests while you have absolute
                right to object to direct marketing
              </Trans>
            </td>
            <td className="ant-table-cell">
              <Trans>
                3 months after the account is deleted; or immediately after deleting the account, if
                you ask for
              </Trans>
            </td>
          </tr>
          <tr>
            <td className="ant-table-cell">
              <Trans>Send you emails with our offers</Trans>
            </td>
            <td className="ant-table-cell">
              <ul>
                <li>
                  <Trans>Email</Trans>
                </li>
              </ul>
            </td>
            <td className="ant-table-cell">
              <Trans>
                Our legitimate interest is based on the balance of interests while you have the
                absolute right to object to direct marketing. To unsubscribe from receiving emails,
                you can click the appropriate “Unsubscribe” button in the email you receive
              </Trans>
            </td>
            <td className="ant-table-cell">
              <Trans>
                3 months after the account is deleted; or immediately after deleting the account, if
                you ask for
              </Trans>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <Trans>
          Please note, that we do not collect any special categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or philosophical beliefs,
          sex life, sexual orientation, political opinions, trade union membership, information
          about your health, and genetic and biometric data). Nor do we collect any information
          about criminal convictions and offenses.
        </Trans>
      </p>

      <h5>
        <Trans>WHEN CAN WE SHARE YOUR PERSONAL DATA?</Trans>
      </h5>
      <p>
        <Trans>
          We can share your Personal Data with third parties only in the cases listed below.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="When we are required by law. We may disclose your Personal Data to the extent that we are required to do so by law (which may include to government bodies and law enforcement agencies), in connection with any legal proceedings or prospective legal proceedings and in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention).">
          <strong>When we are required by law.</strong> We may disclose your Personal Data to the
          extent that we are required to do so by law (which may include to government bodies and
          law enforcement agencies), in connection with any legal proceedings or prospective legal
          proceedings and in order to establish, exercise or defend our legal rights (including
          providing information to others for the purposes of fraud prevention).
        </Trans>
      </p>
      <p>
        <Trans i18nKey="With our partners who help us support the Software. We may use third-party systems to fulfill the purposes set forth in the Privacy Policy. A list of third-party systems used by Software is below.">
          <strong>With our partners who help us support the Software.</strong> We may use
          third-party systems to fulfill the purposes set forth in the Privacy Policy. A list of
          third-party systems used by Software is below.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Payment systems. We may provide paid products and/or services. In that case, we may use third-party services for payment processing, as follows: Qiwi, Stripe, Coinbase. We do not process your payment data; such data is processed by the relevant payment provider.">
          <strong>Payment systems.</strong> We may provide paid products and/or services. In that
          case, we may use third-party services for payment processing, as follows: Qiwi, Stripe,
          Coinbase. We do not process your payment data; such data is processed by the relevant
          payment provider.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Analytics systems. We may use third-party service providers to monitor and analyze the use of our Software, as follows: Google Tag Manager, Google Analytics, Yandex Metrica.">
          <strong>Analytics systems.</strong> We may use third-party service providers to monitor
          and analyze the use of our Software, as follows: Google Tag Manager, Google Analytics,
          Yandex Metrica.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Chat. To automate support chat, we use Talk-me.">
          <strong>Chat.</strong> To automate support chat, we use Talk-me.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="Emailing. We use Postmark third-party services to deliver emails quickly.">
          <strong>Emailing.</strong> We use Postmark third-party services to deliver emails quickly.
        </Trans>
      </p>
      <p>
        <Trans>
          If you wish to receive more information about these third-party systems, please contact
          our team by email indicated in this Privacy Policy.
        </Trans>
      </p>
      <p>
        <Trans>
          OCTO will only transfer Personal Data to, or allow access by, third parties when it is
          assured that the information will be processed legitimately and protected appropriately by
          the recipient.
        </Trans>
      </p>

      <h5>
        <Trans>HOW LONG DO WE RETAIN YOUR PERSONAL DATA?</Trans>
      </h5>
      <p>
        <Trans>
          We retain your Personal Data for no longer than it is necessary to fulfill the purposes
          specified in the section “WHAT PERSONAL DATA WE USE AND HOW”. Except for any legal
          obligation that sets a longer data retention period, at the end of these periods, the
          Personal Data processed will be deleted or anonymized.
        </Trans>
      </p>

      <h5>
        <Trans>HOW WE PROTECT YOUR PERSONAL DATA?</Trans>
      </h5>
      <p>
        <Trans>We take precautions to protect the security of your Personal Data.</Trans>
      </p>
      <p>
        <Trans>
          We have physical, electronic, and managerial procedures to help safeguard, prevent
          unauthorized access, maintain data security, and correctly use your information:
          encryption, pseudonymization, firewalls, limited access, use of passwords, and other
          relevant measures as appropriate. All supplied sensitive/credit information is transmitted
          via Secure Socket Layer (SSL) technology and then encrypted into our payment gateway
          providers database only to be accessible by those authorized with special access rights to
          such systems, and are required to keep the information confidential. After a transaction,
          your private information (credit/debit cards, financials, etc.) is never kept on file.
        </Trans>
      </p>
      <p>
        <Trans>
          In addition, we limit access to your Personal Data to those employees, agents,
          contractors, and other third parties who have a business need to know. They will only
          process your Personal Data on our instructions and they are subject to a duty of
          confidentiality.
        </Trans>
      </p>
      <p>
        <Trans>
          For the safe storage of information, we use Cloud Storage on AWS (Frankfurt, Germany). We
          will use all reasonable efforts to safeguard your Personal Data and for this purpose, we
          undertake to review our security procedures and technical and organizational measures
          regularly in order to account for the latest technological developments.
        </Trans>
      </p>

      <h5>
        <Trans>LINKS</Trans>
      </h5>
      <p>
        <Trans>
          Our Software may contain links to and from other websites and services. We are not
          responsible for the privacy practices or the content of any third-party sites. Please
          check the individual privacy policy of any such websites or services before you submit any
          personal information to them.
        </Trans>
      </p>

      <h5>
        <Trans>DO WE USE AUTOMATED DECISION-MAKING OR REFER TO THE AUTOMATED PROFILING?</Trans>
      </h5>
      <p>
        <Trans>We neither use automated decision-making nor refer to automated profiling.</Trans>
      </p>

      <h5>
        <Trans>WHAT RIGHTS DO YOU HAVE?</Trans>
      </h5>
      <p>
        <strong>
          <Trans>User rights (including EU residents):</Trans>
        </strong>
      </p>

      <ol>
        <li>
          <Trans i18nKey="Right to access. This right enables you to receive a copy of the Personal Data we hold about you, as well as other supplementary information.">
            <strong>Right to access.</strong> This right enables you to receive a copy of the
            Personal Data we hold about you, as well as other supplementary information.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to rectification. You have the right to have any incomplete or inaccurate information we hold about you corrected.">
            <strong>Right to rectification.</strong> You have the right to have any incomplete or
            inaccurate information we hold about you corrected.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to erasure (right to delete). You have the right to ask us to delete your Personal Data when there is no other legal ground for us to continue processing it.">
            <strong>Right to erasure (right to delete).</strong> You have the right to ask us to
            delete your Personal Data when there is no other legal ground for us to continue
            processing it.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to object processing, where we are relying on a legitimate interest and there is something about your particular situation that makes you want to object to the processing on this ground. We will no longer process the Personal Data unless we demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defense of legal claims. You also have the right to object to use of your personal information for direct marketing purposes.">
            <strong>Right to object processing,</strong> where we are relying on a legitimate
            interest and there is something about your particular situation that makes you want to
            object to the processing on this ground. We will no longer process the Personal Data
            unless we demonstrate compelling legitimate grounds for the processing which override
            your interests, rights and freedoms or for the establishment, exercise or defense of
            legal claims. You also have the right to object to use of your personal information for
            direct marketing purposes.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to transfer. You may request the transfer of your personal information to another party.">
            <strong>Right to transfer.</strong> You may request the transfer of your personal
            information to another party.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to data portability. We will provide to you, or to a third-party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which is processed under your consent or EULA and Terms of Use. We are not able to guarantee technical compatibility with a third-party organisation’s systems.">
            <strong>Right to data portability.</strong> We will provide to you, or to a third-party
            you have chosen, your Personal Data in a structured, commonly used, machine-readable
            format. Please note that this right only applies to automated information which is
            processed under your consent or EULA and Terms of Use. We are not able to guarantee
            technical compatibility with a third-party organisation’s systems.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to withdraw consent. You have the right to withdraw your consent to the use of your Personal Data.">
            <strong>Right to withdraw consent.</strong> You have the right to withdraw your consent
            to the use of your Personal Data.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="Right to restrict processing of your Personal Data if (a) the accuracy of the Personal Data is contested by you, (b) the processing is unlawful and you oppose the erasure of the Personal Data, (c) we no longer need the Personal Data for the purposes of the processing, but they are required by you for the establishment, exercise or defense of legal claims, and (d) you have objected to processing pending the verification whether the legitimate grounds override this.">
            <strong>Right to restrict processing</strong> of your Personal Data if (a) the accuracy
            of the Personal Data is contested by you, (b) the processing is unlawful and you oppose
            the erasure of the Personal Data, (c) we no longer need the Personal Data for the
            purposes of the processing, but they are required by you for the establishment, exercise
            or defense of legal claims, and (d) you have objected to processing pending the
            verification whether the legitimate grounds override this.
          </Trans>
        </li>
      </ol>

      <p>
        <Trans>
          We will address your request as early as possible and no longer that within 1 month.
          Please note that this period may be extended by 2 further months where necessary, taking
          into account the complexity and number of the requests. In this case, we will inform you
          of the extension within 1 month of receipt of your request and will explain you the
          reasons for the delay.
        </Trans>
      </p>

      <h5>
        <Trans>Rights of California residents:</Trans>
      </h5>
      <ol>
        <li>
          <div>
            <Trans i18nKey="Right to opt out. Under the CCPA each California resident can request business stops selling Personal Data to third parties.">
              <strong>Right to opt out.</strong> Under the CCPA each California resident can request
              business stops selling Personal Data to third parties.
            </Trans>
          </div>
          <div>
            <Trans i18nKey="Do we actually “sell” Personal Data? We do not, and will not, provide your Personal Data in direct exchange for money. Therefore, in the literal sense, we do not sell your Personal Data.">
              <strong>Do we actually “sell” Personal Data?</strong> We do not, and will not, provide
              your Personal Data in direct exchange for money. Therefore, in the literal sense, we
              do not sell your Personal Data.
            </Trans>
          </div>
        </li>
        <li>
          <Trans i18nKey="Other rights. You also have a right to be informed about what categories of Personal Data we are collecting: you can request us to disclose what Personal Data we have collected in the past 12 months and right to get a free copy of your Personal Data disclosed in a readily usable and readable format. You can also request us to delete the Personal Data we have collected in the past 12 months. We will not discriminate against you for exercising any of your rights granted under CCPA.">
            <strong>Other rights.</strong> You also have a right to be informed about what
            categories of Personal Data we are collecting: you can request us to disclose what
            Personal Data we have collected in the past 12 months and right to get a free copy of
            your Personal Data disclosed in a readily usable and readable format. You can also
            request us to delete the Personal Data we have collected in the past 12 months. We will
            not discriminate against you for exercising any of your rights granted under CCPA.
          </Trans>
        </li>
      </ol>

      <p>
        <Trans i18nKey="Mandatory Verification: As required by CCPA we will need to verify your identity before processing your request. In order to verify your identity, you will be asked to log in to your account or (if you do not have an account) we will try to match the information you provided with the information we handle about you. In certain circumstances, we may decline the request, mainly where we are unable to verify your identity, for example, if you have requested us to delete your Personal Data.">
          <strong>Mandatory Verification:</strong> As required by CCPA we will need to verify your
          identity before processing your request. In order to verify your identity, you will be
          asked to log in to your account or (if you do not have an account) we will try to match
          the information you provided with the information we handle about you. In certain
          circumstances, we may decline the request, mainly where we are unable to verify your
          identity, for example, if you have requested us to delete your Personal Data.
        </Trans>
      </p>
      <p>
        <Trans>
          As required by CCPA we endeavor to respond to a verifiable request within 45 days of its
          receipt. If we require more time (up to 90 days), we will inform you of the reason and
          extension period in writing. We will deliver our written response by email.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="How to exercise any of your rights? You may exercise your rights by sending a relevant request to the email indicated in the contact details.">
          <strong>How to exercise any of your rights?</strong> You may exercise your rights by
          sending a relevant request to the email indicated in the contact details.
        </Trans>
      </p>
      <p>
        <Trans>
          If you have any comments about how we process your Personal Data, please let us know and
          we will consider your claim. If you are not satisfied with our response to the complaint,
          you have the right to file a complaint with the competent authority.
        </Trans>
      </p>

      <h5>
        <Trans>CHILDREN DATA</Trans>
      </h5>
      <p>
        <Trans i18nKey="We do not knowingly collect information from children under the age of 13 (16 – in the European Economic Area) or knowingly allow such persons to use the Software. If you are under the age of 13 (or 16, where applicable), please do not provide any Personal Data to us. If you find out that the child has provided us with personal information in violation of this Privacy Policy, you can notify us at privacy@octobrowser.net.">
          We do not knowingly collect information from children under the age of 13 (16 – in the
          European Economic Area) or knowingly allow such persons to use the Software. If you are
          under the age of 13 (or 16, where applicable), please do not provide any Personal Data to
          us. If you find out that the child has provided us with personal information in violation
          of this Privacy Policy, you can notify us at <a href={`mailto:${PRIVACY_EMAIL_ROUTE}`}>{PRIVACY_EMAIL_ROUTE}</a>.
        </Trans>
      </p>

      <h5>
        <Trans>CHANGES TO OUR PRIVACY POLICY</Trans>
      </h5>
      <p>
        <Trans>
          We may occasionally update this Privacy Policy. If we decide to do so, we will post those
          changes on this page and update the date at the top of the page. Your continued use of the
          Software after the effective date of the updated Privacy Policy will be subject to the new
          Privacy Policy. If we make any major changes to our Privacy Policy and will need your
          explicit consent for further processing of your Personal Data, we will request your
          consent or your renewed consent (in case it was obtained previously).
        </Trans>
      </p>

      <h5>
        <Trans>HOW TO CONTACT US?</Trans>
      </h5>
      <p>
        <Trans>
          In accordance with this Privacy Policy, BLACKBIRD SECURE BROWSING LTD is the “controller”
          or a “data controller”, i.e. the person which determines the purposes and means of the
          processing of your Personal Data.
        </Trans>
      </p>
      <p>
        <Trans>
          If you have questions, complaints or need further information about our Privacy Policy,
          please contact us, using the details below:
        </Trans>
      </p>
      <div>
        <div>
          <Trans>BLACKBIRD SECURE BROWSING LTD</Trans>
        </div>
        <div>
          <Trans>
            Address: Unit 1058, Second Floor, 6 Market Place, London, Fitzrovia, United Kingdom, W1W
            8AF
          </Trans>
        </div>
        <div>
          <Trans i18nKey="Email: privacy@octobrowser.net">
            Email: <a href={`mailto:${PRIVACY_EMAIL_ROUTE}`}>{PRIVACY_EMAIL_ROUTE}</a>
          </Trans>
        </div>
      </div>

      <p>
        <Trans>
          We have appointed a representative in the EU who you can contact, if you would prefer not
          to contact BLACKBIRD SECURE BROWSING LTD directly:
        </Trans>
      </p>
      <div>
        <div>
          <Trans>FINWIN TECHNOLOGIES Sp. z o.o.</Trans>
        </div>
        <div>
          <Trans>Address: 6/32 Warszawska st, Bialystok, Poland</Trans>
        </div>
        <div>
          <Trans i18nKey="Email: finwinpl@gmail.com">
            Email: <a href={`mailto:${FINWINPL_EMAIL_ROUTE}`}>{FINWINPL_EMAIL_ROUTE}</a>
          </Trans>
        </div>
      </div>
    </StaticPage>
  );
};

export default PrivacyPolicy;
